import React, { useState } from "react";
import "./footer.scss";

import { AppWrap, MotionWrap } from "../../wrapper";
import { images } from "../../constants";
import { client } from "../../client.js";
import { getCurrentDateTime } from "../../utils";
const Footer = () => {
  const [form, setForm] = useState({ name: "", message: "", email: "" });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, message } = form;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
    console.log(form);
  };
  const handleSubmit = () => {
    setLoading(true);

    //submit to sanity
    const contact = {
      _type: "contact",
      name,
      email,
      message,
      recievedAt: getCurrentDateTime(),
    };
    client.create(contact).then(() => {
      setLoading(false);
      setSubmitted(true);
      setForm({ name: "", message: "", email: "" });
    });
  };
  return (
    <>
      <h2 className="head-text">
        Take a Coffee & <span>Chat</span> with me
      </h2>
      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:srai86825@gmail.com">srai86825@gmail.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="email" />
          <a href="tel:+91 6203927929">+91 6203927929</a>
        </div>
      </div>

      {!submitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              className="p-text"
              name="name"
              value={name}
              placeholder="Your Name"
              onChange={handleChange}
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              name="email"
              value={email}
              placeholder="Your email"
              onChange={handleChange}
            />
          </div>
          <div>
            <textarea
              className="p-text"
              name="message"
              value={message}
              onChange={handleChange}
            />
          </div>
          <button type="button" onClick={handleSubmit}>
            {loading ? "Sending..." : "Send Message"}
          </button>
        </div>
      ) : (
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
          <h1 className="head-text">
            <span>Message Recieved!</span>
          </h1>
          <h3 className="p-text">
            Thanks for getting in touch, you will hear from me ASAP.
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "Contact",
  "app__whitebg"
);
