function getCurrentDateTime() {
  var currentDate = new Date();

  var hours = currentDate.getHours();
  var minutes = currentDate.getMinutes();
  var seconds = currentDate.getSeconds();

  var day = currentDate.getDate();
  var month = currentDate.getMonth() + 1; // Months are zero-based
  var year = currentDate.getFullYear();

  // Add leading zeros if necessary
  hours = addLeadingZero(hours);
  minutes = addLeadingZero(minutes);
  seconds = addLeadingZero(seconds);
  day = addLeadingZero(day);
  month = addLeadingZero(month);

  var dateTimeString =
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    " " +
    day +
    "/" +
    month +
    "/" +
    year;

  return dateTimeString;
}

function addLeadingZero(value) {
  return value < 10 ? "0" + value : value;
}

export { getCurrentDateTime };
