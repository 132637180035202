import React, { useState, useEffect } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";

import { AppWrap,MotionWrap } from "../../wrapper";
import "./work.scss";

import { urlFor, client } from "../../client";

const Work = () => {
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ x: 0, opacity: 1 });
  const [filterWorks, setFilterWorks] = useState([]);
  const [works, setWorks] = useState([]);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard({ x: -100, opacity: 0 });
    console.log(works); 
    setTimeout(() => {
      setAnimateCard({ x:0, opacity: 1 });
      if (item === "All") setFilterWorks(works);
      else {
        setFilterWorks(works.filter((work) => work.tags?.includes(item)));
      }
    }, 500);
  };

  useEffect(() => {
    const query = '*[_type=="works"]';
    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWorks(data);
    });
  }, []);
  return (
    <div className="app__works">
      <h2 className="head-text">
        My Creative <span>Work</span> Portfolio{" "}
      </h2>
      <div className="app__work-filter">
        {["UI/UX", "MERN", "React.js", "Next.js", "All"].map((item, i) => (
          <div
            key={`filter-${i}`}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? "active-filter" : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.6, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWorks.map((work, i) => (
          <div className="app__work-item app__flex" key={`work-${i}`}>
            <div className="app__work-img app__flex">
              <img
                src={urlFor(work?.imgUrl) || "www.demp.com"}
                alt={work?.name}
              />
              <p className="p-text app__work-tag">
                {work?.tags?.length ? work.tags[0] : ""}
              </p>
              {/*experiment */}
              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  delay: 0.2,
                  staggerChildren: 0.5,
                  ease: "easeInOut",
                }}
                className="app__work-hover app__flex"
              >
                <a href={work?.projectLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{
                      duration: 0.25,
                    }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>

                <a href={work?.codeLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{
                      duration: 0.2,
                    }}
                    className="app__flex"
                  >
                    <AiFillGithub />
                  </motion.div>
                </a>
              </motion.div>
            </div>
            <div className="app__work-content app__flex">
              <h4 className="bold-text">{work?.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {work?.description}
              </p>
              {/* <p className="p-text app__work-tag">{work.tags[0]}</p> */}
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(MotionWrap(Work,"app__work"),"Work","app__primarybg");
