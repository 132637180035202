import React from "react";
import { About, Footer, Header, Skills, Testimonial, Work } from "./containers";
import { Nav } from "./components";
import "./app.scss"
const App = () => {
  return (
    <div className="app">
      <Nav />
      <Header />
      <About />
      <Work />
      <Skills />
      <Testimonial />
      <Footer />
    </div>
  );
};

export default App;
