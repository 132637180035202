import React, { useState } from "react";
import { motion } from "framer-motion";
import { HiMenuAlt4, HiX } from "react-icons/hi";

import { images } from "../../constants";
import cv from "../../assets/cv.pdf";
import "./nav.scss";

const Nav = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__nav">
      <div className="app__nav-logo">
        <img src={images.logo} alt="Logo" />
      </div>
      <ul className="app__nav-links">
        {["Home", "About", "Work", "Skills", "Contact"].map((item) => (
          <li key={`key-${item}`} className="app__flex p-text">
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>
      <div className="cv-download desktop-button">
        <a href={cv} download={"Saurabh-Kumar-Rai-CV"}>
          My Portfolio
        </a>
      </div>

      <div className="app__nav-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.55, ease: "easeIn" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {["Home", "About", "Work", "Skills", "Contact"].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
              <li className="cv-download mobile-button">
                  <a href={cv} download={"Saurabh-Kumar-Rai-CV"}>
                    My Portfolio
                  </a>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Nav;
